.Collegegraduate-top-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}
.Collegegraduate-top-btn button{
    background-color: #ffdd7e;
    padding: 10px 30px;
    border: none;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
}
.Collegegraduate-top-btn button:hover{
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}