.Ourteam-top-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;

}
.Ourteam-top-btns p{
    font-size: 30px;

}
.Ourteam-top-btns button{
    background-color: #ffdd7e;
    padding: 10px 30px;
    border: none;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
}
.adimin-filter-box{
    background-color: #fff;
    width: 90%;
    height: 100px;
    margin: 5% auto;
    border-radius: 10px;
    display: grid;
    place-items: center;
}

.switch {
 position: relative;
 display: inline-block;
 width: 120px;
 height: 34px;
}

.switch input {
 display: none;
}

.slider {
 position: absolute;
 cursor: pointer;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-color: #3C3C3C;
 -webkit-transition: .4s;
 transition: .4s;
 border-radius: 34px;
}

.slider:before {
 position: absolute;
 content: "";
 height: 26px;
 width: 29px;
 left: 4px;
 bottom: 4px;
 background-color: white;
 -webkit-transition: .4s;
 transition: .4s;
 border-radius: 50%;
 font-size: 12px;
}

input:checked + .slider {
 background-color: #0E6EB8;
}

input:focus + .slider {
 box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
 -webkit-transform: translateX(26px);
 -ms-transform: translateX(26px);
 transform: translateX(85px);
}

/*------ ADDED CSS ---------*/
.slider:after {
 content: 'make a Admin';
 color: white;
 display: block;
 position: absolute;
 transform: translate(-50%,-50%);
 top: 50%;
 left: 62%;
 font-size: 10px;
 font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
 content: 'Disable Admin';
 left: 40%;
}

/*--------- END --------*/

.group {
 display: flex;
 line-height: 28px;
 align-items: center;
 position: relative;
 max-width: 190px;
}

.input {
 width: 100%;
 height: 40px;
 line-height: 28px;
 padding: 0 1rem;
 padding-left: 2.5rem;
 border: 2px solid transparent;
 border-radius: 8px;
 outline: none;
 background-color: #f3f3f4;
 color: #0d0c22;
 transition: .3s ease;
}

.input::placeholder {
 color: #9e9ea7;
}

.input:focus, input:hover {
 outline: none;
 border-color: rgba(234,76,137,0.4);
 background-color: #fff;
 box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.icon {
 position: absolute;
 left: 1rem;
 fill: #9e9ea7;
 width: 1rem;
 height: 1rem;
}



.team-admin-form {
    margin-top: -20%;
    margin-left: -6%;
    width: 98%;
    z-index: 3;
}

.team-edit-form{
    display: grid;
    place-items: center;
    grid-template-columns: repeat(1,1fr);
    background-color: #9e9ea7;
    width: 100%;
    height: 500px;
    border-radius: 10px;
}
.team-edit-form input{
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
}