.video-add-top-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
}
.video-edit-pop{
  background-color: #ffdd7e;
  border: 1px  solid #ffdd7e;
  display: grid;
  place-items: center; 
  border-radius: 20px;
  height: auto;
  padding: 30px;
  width: 400px;

}
.video-edit-input{
  display:grid;
  place-items: center;
  grid-template-columns: repeat(1video, 1fr);
}
.video-edit-input-div{
  display: flex;
  flex-direction: column;
}
.video-edit-input label{
  font-size: 20px;
  /* color: white; */
  margin-right: 20px;
  text-align: end;
   font-family: "Questrial", sans-serif;
}
.video-edit-input span{
  color:red;
}
.video-edit-close{
  font-size: 30px;
  margin-left: 80%;
}
.video-edit-input input{
  padding: 10px 30px;
  margin: 10px;
  border-radius: 20px;
}
.video-edit-pop button{
  background-color: #ffdd7e;
  padding: 10px 30px;
  border: none;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
}
.video-add-top-btn button {
  background-color: #ffdd7e;
  padding: 10px 30px;
  border: none;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
}
.no-data{
  background-color: red;
  z-index: 3;
}
.fiter-box{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
  width: 90%;
  margin: 30px auto;
  border-radius: 20px;
}
.fiter-box img{
  border-right: 1px solid black;
}
.fiter-box-text p{
  border-right: 1px solid black;
}
.fiter-box input{
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
}
.fiter-box button{
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffdd7e;
  margin-left: 20px;
}
.video-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: fixed; */
    margin-left: 10%;
    margin-top: 27%;
    height: 100vh;
    z-index: 9999;
}
.video-submit-btns{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2rem;
}
.edit-form-cancel {
    display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 50%;
}
.video-submit-btns button:nth-child(1){
  background-color: #BDBDBD;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
}
.video-submit-btns button:nth-child(2){
  background-color: #FFDD7E;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
}
.video-popup-content {
  height: auto;
  width: auto;
  background-color: white;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* justify-content: center; */
  border: 2px solid black;
  border-radius: 20px;
  padding: 50px;
  -webkit-box-shadow: 12px 8px 54px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 12px 8px 54px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 12px 8px 54px -11px rgba(0, 0, 0, 0.75);
}
.admins-video-add tr {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #ffdd7e; */
}

.header-admin {
  /* background-color: #aaa; */
  padding: 1%;
  border-radius: 3px;
}
.header-admin h2 {
  text-align: center;
  text-shadow: 1px 3px 8px #0b1544;
}
table {
  width: 100%;
}
table tr td {
  text-align: center;
}
.video-top-inputs {
  display: grid;
  place-items: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.video-top-inputs input {
  width: 304px;
  /* margin: 10px; */
  padding: 10px;
  border-radius: 10px;
  border: 1px solid green;
  margin-left: 10px;
}
.select-page-videos select {
  width: 300px;
  /* background-color: #0b1544; */
  padding: 10px;
  border-radius: 5px;
}
.video-thumbnil-inputs input {
  width: auto;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  margin-left: 30px;
}
.video-thumbnil-inputs {
  margin: 20px;
}
.func-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.func-btns button {
  margin-top: 5px;
  padding: 10px 10px;
}
.video-admin-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  border: 2px dashed black;
  height: 200px;
  width: auto;
  background-image: url("../../Images/GreenMountain.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 10px;
}
/* Hide the actual file input */
.video-admin-inputs input[type="file"] {
  display: none;
}

/* Style a label to resemble a button */
.video-admin-inputs label {
  color: white;
  background-color: #cd4631;
  user-select: none;
  padding: 10px 45px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block; /* Ensure label takes up space */
}

/* Hide the file input but keep it accessible via label */
.video-admin-inputs input[type="file"] + label:before {
  content: "Browse";
}


.video-admin-inputs input[type="file"]:not(:focus) + label:after {
  content: attr(data-file-name);
  margin-left: 10px;
}
.video-admin-inputs img {
  width: 100px;
}

.table-for-admint-videos {
  width: 100%;
  overflow-x: auto;
}

.rwd-table th {
  background-color: #f2f2f2; 
  padding: 10px; 
  margin: 20px; 
}

.rwd-table {
  width: 100%;
  margin-bottom: 1em;
  border-collapse: collapse;
  border-spacing: 0;
}

.rwd-table th,
.rwd-table td {
  padding: 0.5em;
  border: 1px solid #ddd;
}


@media (max-width: 600px) {
  .rwd-table tr {
    border: 1px solid #ddd;
    margin-bottom: 1em;
    display: block;
  }

  .rwd-table td {
    display: block;
    text-align: right;
  }

  .rwd-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    display: inline-block;
    width: 6.5em;
  }
}
* {
  margin: 0;
  padding: 0;
}

.table-wrap {
  max-width: 1106px;
  margin: 80px auto;
  overflow-x: auto;
}

table,
td,
th {
 
  text-align: left;
  font-size: 18px;
  text-transform: capitalize;
}

table thead tr {
  background-color: white;
  height: 100px;
  margin-bottom: 300px;
  border-radius: 30px;
  padding: 20px;
  color: black;
  font-size: 20px;
  -webkit-box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.75);
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
}

table tbody tr td {
  /* border: 1px solid #ddd; */
}

th,
td {
  padding: 15px;
  white-space: nowrap;
}

table tbody tr:nth-child(odd) {
  background: white;
  color: #000;
  font-weight: 500;
}

.box-wrap {
  padding: 0px 16px;
}


.table-body {
  background-color: white;
}
.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #fff;
  color: #333;
  text-align: center;
  height: 100px;
}
.table-container table tbody tr button {

  padding: 10px 20px 10px 20px;
  background-color: white;
}
.table-container table tbody tr{
  margin: auto;
}
.box-wrap{
  background-color: white;
  height: 900px;
  width: 90%;
  margin: auto;
  border-radius: 20px;
}
.action-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.action-buttons button{
  padding: 10px ;
  width: 100px;
  border-radius: 20px;
}
.action-buttons button:nth-child(2){
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #CD4631;
  color: white;
  border: none;
}
.edit-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: royalblue;
  border-radius: 20px;
}
.edit-form input{
  padding: 10px 30px;
  margin: 10px;
  border-radius: 20px;
}
.edit-form label{
  font-size: 20px;
  color: white;
}
.action-buttons button:nth-child(1){
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  background-color: white;
  color: black;
  border: 1px solid #CD4631;
}
.action-buttons-save button{
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  background-color: white;
  color: black;
  border: 1px solid lawngreen;
  border-radius: 20px;
    margin-left: 35%;
}
.video-edit{
  font-size: 20px;
  
}
.video-delete-new{
  font-size: 20px;
  color: #CD4631;
}
.video-save{
  font-size: 20px;
  color: lawngreen;

}