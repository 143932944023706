.logo img {
  width: 20%;
}
.logo img{
  width: 50%;
}
.logo{
  width:30%;
    font-family: "Questrial", sans-serif;

}
.footer-list-ul{
width: 30%;
}
.footer-list-ul li{
  list-style: none;
  font-family: "Questrial", sans-serif;
}
.contact{
width: 30%;
  font-family: "Questrial", sans-serif;

}

.footer-main {
  background-color: black;
  padding: 30px;
  z-index: -1;
  margin-top: -24px;
}
.two-flower {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}
.footer-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.footer-list li {
  list-style: none;
  gap: 1rem;
  color: white;
  padding: 10px;
}
.footer-list li label {
  font-size: 30px;
  margin-top: 130px;
  border-bottom: 1px solid goldenrod;
}
.footer-icons {
  display: flex;
}
.footer-icon {
  background: #bae8ac;
  font-size: 40px;
  padding: 10px;
  color: black;
}
.news-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bae8ac;
  width: 214%;
  margin-left: -81%;
  /* margin: auto; */
  border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bae8ac;
    width: 137%;
    margin-left: -28%;
    /* margin: auto; */
    border-radius: 20px;
    margin-bottom: 20px;
}
.news-letter p {
  font-size: 15px;
  margin-left: 20px;
  font-family: "Courier New", Courier, monospace;
}
.news-letter input {
  width: 78%;
  height: 20px;
  margin-left: 3%;
  padding: 27px;
  border-radius: 20px;

    width: 104%;
    height: 20px;
    margin-left: 3%;
    padding: 27px;
    border-radius: 20px;
    flex: 0 0 360px;
}
.news-letter-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-letter-main button {
  background-color: #bae8ac;
  padding: 10px 40px;
  border-radius: 20px;
  margin-left: 13%;
}
@media screen and (max-width: 600px) {

  .logo img{
  width: 100%;
}
.logo{
  width:100%;
    font-family: "Questrial", sans-serif;

}
.footer-list-ul{
width: 100%;
}
.contact {
    width: 100%;
    font-family: "Questrial", sans-serif;
}
.news-letter input {
        width: 44px;
        height: 61px;
        margin-left: -1%;
        padding: 11px;
        border-radius: 20px;
        flex: 0 0 160px;
    }
  .Navbar-main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #bae8ac;
    height: 120px;
    /* border: 1px solid black; */
  }
  .logo img {
    width: 40%;
  }
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    margin-top: -3%;
  }
  .two-flower {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    background-color: red;
    /* width: 10%; */
    display: none;
  }
  .footer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .footer-main {
    background-color: black;
    padding: 30px;
    z-index: -1;
    width: 100%;
  }
  .news-letter p {
    font-size: 15px;
    /* margin-left: 20px; */
    font-family: "Courier New", Courier, monospace;
  }
  .news-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bae8ac;
    width: 104%;
    height: 59px;
    margin: auto;
    border-radius: 20px;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .two-flower {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    background-color: red;
    /* width: 10%; */
    display: none;
  }
    /* background-color: red; */
    /* width: 10%; */
    /* display: none; */
  
   .two-flower{
    position: relative;
   }
   .two-flower img{
    width: 50%;
   }
   .two-flower img:nth-child(2){
    /* position: absolute; */
    width: 50%;
    margin-top: 50px;
    /* background-color: red; */
   }
  .logo img {
    width: 40%;
  }
  .news-letter input {
    width: 67%;
    width: 68%;
    height: 20px;
    margin-left: 3%;
    padding: 27px;
    border-radius: 20px;
  }
  .news-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bae8ac;
    width: 161%;
    width: 140%;
    margin-left: -26%;
    /* margin: auto; */
    border-radius: 20px;
  }
  .news-letter-main {
    /* margin-top: 40px; */
    flex-direction: column;
  }

  .news-letter-main button {
    margin: 20px;
  }
}


/* @media screen and (max-width: 1224px) {
  .two-flower {
    display: flex;
    align-items: center;

    background-color: red;
    
    display: none;
  }
  .news-letter input {
    width: 67%;
    height: 20px;
    margin-left: 3%;
    padding: 27px;
    border-radius: 20px;
  }
  .news-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bae8ac;
    width: 104%;
    /* margin-left: -26%; */
    /* margin: auto; */
    /* border-radius: 20px; */
  /* }
} */
 
/* }  */
.whatsapp-fix-img{
  width: 70%;
  position: absolute;
  border-radius: 50%;
font-size: 50px !important;
animation: pulse 2s infinite;
  
}

.callIcon-fix-img{
  width: 70%;
  position: absolute;
  border-radius: 50%;
font-size: 50px !important;
animation: pulse 2s infinite;
}

.w-app{
  /* background-color: #e4e4e4; */
  border-radius: 15px;
  display: flex;
  align-items: center;
  width: 135px;
  height: 38px;
  position: fixed;
  bottom: 10%;
  right:-10px;
}
.call-icon{
  display: flex;
  align-items: center;
  width: 135px;
  height: 38px;
  position: fixed;
  bottom: 25%;
  right:-10px;
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 green;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}