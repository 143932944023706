.tele-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 10px;
  margin-top: 20px;
}
.top-baner img:nth-child(1){
  width: 200px;
  /* background-color: red; */
  
}
.top-baner-inside{
  text-align: center;
}
.top-baner img:nth-child(3){
  width: 100px;
  background-color: yellow;
  margin-top: -160px;
  
}
.dis-fle{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.tele-buttons button {
  background-color: #ffdd7e;
  padding: 10px 30px;
  border: none;
  font-size: 16px;
  border-radius: 20px;
}
.tele-buttons p {
  font-size: 26px;
  border-bottom: 3px dashed #256c55;
}
.arrow-for-tele {
  font-size: 16px;
  background: #ffdd7e;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.top-baner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px auto;
  background-color: #ffdd7e;
  width: 100%;
  height: 250px;
}

.top-baner p {
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
  text-decoration: underline;
}
.top-baner h1 {
  font-family: Relo;
  font-weight: 900;
  font-size: 60px;
}
.top-baner h2 {
  font-family: myFont;
  color: #256c55;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  margin-top: -3%;
}
.card {
  position: relative;
  width: 350px;
  height: 600px;
  background: white;
  border: 2px solid #ffdd7e;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: rgba(141, 177, 205, 0.618) 0px 40px 30px -25px;
  transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: 17px 18px 15px -3px rgba(0, 0, 0, 0.1);
}
/* .card:nth-child(2) {
  background-color: black;
} */
.card:hover {
  transform: scale(1.08);
  box-shadow: rgba(141, 177, 205, 0.618) 0px 30px 30px -25px;
}
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: -3%;
}
.card-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}
/* .card:hover {
  background-color: black;
} */
.card-text img {
  width: 100px;
}
.card p {
  margin: 10px 20px;
}
.card span {
  font-size: 16px;
}
.card h1,
li {
  margin: 10px 20px;
}
.card li {
  margin: 10px 45px;
  padding: 6px;
  list-style: none;
}
.card button {
  background-color: #ffdd7e;
  padding: 10px 20px;
  margin-left: 30%;
  border: none;
  border-radius: 20px;
}
.tick {
  background: #256c55;
  /* padding: 10px; */
  font-size: 20px;
  margin-right: 10px;
  color: white;
  border-radius: 50px;
}
.bottom-tele-image {
  display: flex;
    align-items: center;
    /* position: relative; */
    justify-content: space-between;
    margin: 5%;
}
.bottom-tele-image img {
  width: 25%;
}

.tele-bottom-box {
    background-color: #bae8ac;
    width: 515px;
    height: 390px;
    /* margin-top: -5%; */
    border-radius: 30px;
    /* flex: 0 0 130px; */
}
.tele-bottom-box-main {
    display: flex;
    flex-wrap: wrap;
    /* flex: 0 0 76px; */
    align-items: center;
    gap: 2rem;
    justify-content: center;
    /* margin-left: 5%; */
}
.imsai-two{
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tele-bottom-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.tele-bottom-image {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.tele-bottom-image img {
  width: 45%;
}
.tele-bottom-image button {
  background-color: #ffdd7e;
  border: none;
  padding: 10px 25px;
  border-radius: 20px;
}
@media screen and (max-width: 600px) {
  .top-baner img:nth-child(3) {
    width: 65px;
    background-color: yellow;
    margin-top: -131px;
}
  .top-baner img:nth-child(1) {
    width: 117px;
    /* background-color: red; */
}
.top-baner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px auto;
    background-color: #ffdd7e;
    width: 100%;
    height: 190px;
}
  .tele-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 10px;
    margin-top: -22px;
}
  .card {
    position: relative;
    width: 310px;
    height: 600px;
    background: white;
    border: 2px solid #ffdd7e;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: rgba(141, 177, 205, 0.618) 0px 40px 30px -25px;
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    box-shadow: 17px 18px 15px -3px rgba(0, 0, 0, 0.1);
  }
  .card:nth-child(2) {
    background-color: black;
    height: 660px;
  }
  .top-baner p {
    font-size: 22px;
  }
  .top-baner h1 {
    font-size: 26px;
  }
  .top-baner h2 {
    font-size: 26px;
  }
  .tele-bottom-box {
    background-color: #bae8ac;
    width: 328px;
    height: 390px;
    margin-top: -5%;
    border-radius: 30px;
    padding: 20px;
  }
  .tele-bottom-box h1 {
    font-size: 23px;
  }

  .tele-buttons button {
    background-color: #ffdd7e;
    padding: 5px 20px;
    border: none;
    font-size: 12px;
    border-radius: 20px;
  }
  .container-speek {
    position: relative;
    text-align: center;
    color: white;
    /* padding: 30px; */
    /* margin-top: 11%; */
  }
  .tele-bottom-box-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    /* margin-left: 10%; */
    gap: 2rem;
    /* margin-bottom: 20px; */
    margin: 30px
  }

  .tele-buttons p {
    font-size: 15px;
    border-bottom: 3px dashed #256c55;
  }
  .arrow-for-tele {
    font-size: 52px;
    background: #ffdd7e;
    /* padding: 10px; */
    width: 41px;
    height: 38px;
    border-radius: 50px;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .top-baner h1 {
    font-family: Relo;
    font-weight: 900;
    font-size: 44px;
}
  .bottom-tele-image {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    /* margin-top: 20%; */
    width: 32%;
    margin-left: -67%;
  }
  .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    margin-top: -3%;
  }
  .tele-bottom-box-main {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: 5rem;
    justify-content: space-between;
    margin: 50px 50%;
  }
  .weeekly-meetup-box img,
  p {
    padding: 16px;
    font-family: "Questrial", sans-serif;
  }
}
