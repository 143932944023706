.error {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    
}
.error p{
   color: red;
  /* margin-left: 5px; */
  font-family: "Questrial", sans-serif;
  font-size: 16px;
}
.error h1{
    z-index: 1;
    font-size: 70px;
        font-family: myFont;
        color: #1188C4;
        letter-spacing: 2px;
        padding: 0 1em;
}
.lottie-error{
    width: 30%;
}
@media screen and (max-width: 600px){
    .lottie-error{
    width: 100%;
}
.error {
    width: 100%;
    height: auto;
    display: grid;
    place-items: center;
}
}
@media screen and (min-width:600px) and (max-width:1024px){
        .lottie-error{
    width: 100%;
}
.error {
    width: 100%;
    height: auto;
    display: grid;
    place-items: center;
}
}