.Links {
  text-decoration: none;
  color: black;
}
.therapy-box img{
  width: 250px;
  aspect-ratio: 1 / 2;
  object-fit: contain;
  /* border-radius: 10px; */
  margin-top: -140px;
}


.therapy-box h1 {
  text-align: center;
  margin: 10px;
  font-family: Relo;
  padding-top: 20px;
}
.eclip{
  position: relative;
  width: 100%;
  /* overflow: hidden; */
}
.eclip img:nth-child(1){
  position: absolute;
  /* background-color: #256c55; */
  margin-top: -5%;
  z-index: -1;
}
.eclip img:nth-child(3){
  position: absolute;
  /* background-color: #256c55; */
  margin-top: -50%;
  margin-left: 39%;
  z-index: -1;
}
.bulb img:nth-child(1) {
  width: 250px;
  animation: moveUpDown 2s infinite;
  animation-timing-function: ease-in-out;
  position: sticky;
}
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust the distance to move up */
  }
  100% {
    transform: translateY(0);
  }
}

.bulb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -35%;
  gap: 13rem;
}
.therapy-box {
  background-color: #ffdd7e;
  border-radius: 20px;
  height: 350px;
  width: 250px;
}
.theropy-box-main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin: auto;
  padding: 10px;
}
.therapy-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* z-index: -1; */
}
.therapy-heading h2 {
  font-family: myFont;
  color: #256c55;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  margin-top: -2%;
}
.therapy-heading h1 {
  font-family: Relo;
  font-weight: 900;
  font-size: 50px;
  text-align: center;
}

.therapy-heading p {
  font-family: "Satisfy", cursive;
  margin-bottom: -1%;
  font-size: 30px;
  text-decoration: underline;
  text-align: center;
  width: 80%;
}
  .therapy-heading span {
    /* background-color: red; */
    margin: auto;
    font-family: "Questrial", sans-serif;
    margin: 10px;
  }
@media screen and (max-width: 600px) {
      .therapy-box img {
        width: 219px;
        aspect-ratio: 1 / 2;
        object-fit: contain;
        /* border-radius: 10px; */
        margin-top: -95px;
        margin-left: 19px;
    }
  .therapy-heading h2 {
    font-family: myFont;
    color: #256c55;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    margin-top: -2%;
}
  .eclip img:nth-child(3){
  position: absolute;
  /* background-color: #256c55; */
  margin-top: -50%;
  margin-left: 39%;
  z-index: -1;
  display: none;
}
  .therapy-heading p {
    font-family: "Satisfy", cursive;
    margin-bottom: -1%;
    font-size: 26px;
    text-decoration: underline;
    width: 90%;
    text-align: center;
}
  .therapy-heading span {
    /* background-color: red; */
    margin: auto;
    font-family: "Questrial", sans-serif;
    margin: 10px;
  }
  .therapy-heading h1 {
          font-family: Relo;
        font-weight: 900;
        font-size: 21px;
        margin: 10px;
        text-align: center;
  }
  .therapy-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-left: 7%; */
  }
  .theropy-box-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    padding: 10px;
  }
  .therapy-box {
    background-color: #ffdd7e;
    border-radius: 20px;
    height: 350px;
    width: 250px;
    margin-bottom: 30px;
  }
  .bulb {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    margin-left: -6%;
    gap: 0rem;
  }
  .bulb img:nth-child(1) {
    width: 123px;
    animation: moveUpDown 2s infinite;
    margin-top: -28%;
    margin-left: 57%;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .therapy-box h1 {
    text-align: center;
    margin: 10px;
    font-family: Relo;
    padding-top: 20px;
    font-size: 19px;
}
  .therapy-box img{
  width: 200px;
  aspect-ratio: 1 / 2;
    object-fit: contain;
    /* border-radius: 10px; */
    margin-top: -100px;
}
  .bulb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20%;
    gap: 0rem;
  }
  .therapy-heading h1 {
    font-family: Relo;
    font-weight: 900;
    font-size: 33px;
    margin: -9px;
  }
  .therapy-heading span {
    margin: 30px;
  }
  .therapy-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: -20%; */
  }
  .therapy-box {
    background-color: #ffdd7e;
    border-radius: 20px;
    height: 317px;
    width: 200px;
  }
 
  .theropy-box-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: auto;
    padding: 10px;
  }
    .therapy-heading span {
    /* background-color: red; */
    margin: auto;
    font-family: "Questrial", sans-serif;
    margin: 10px;
  }
}
