.teampage-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.team-psychologist-box img{
width: 140px;
   height: 200px;
 aspect-ratio: 3/2;
 object-fit: cover;
}
.team-psychologist-box{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffdd7e;
    border: 2px solid yellow;
    width: 260px;
    height: 400px;
    padding: 10px;
    border-radius: 20px;
}
.team-psychologist-box-conatiner {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 0.2fr);
    margin: 20px;
}
.Team-card-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  background-color: #bae8ac;
  width: 840px;
  z-index: 9999;
  border-radius: 20px;
  height: 300px;
  padding: 20px;
}
.Team-card-inner img {
  z-index: 9999;
  width: 30%;
  border-radius: 30px;
}
.Team-card-inner button {
  align-items: left;
  padding: 10px 20px;
  background-color: black;
  border: none;
  color: aliceblue;
  border-radius: 20px;
}
.Team-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffdd7e;
  width: 230px;
  height: 330px;
  border-radius: 30px;
}
.Team-box-container img {
  width: 100%;
  border-radius: 30px;
}
.positon-name-team {
  text-align: center;
}
.team-psycologiest-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffdd7e;
  width: 450px;
  height: 350px;
  border-radius: 30px;
  border: 2px solid black;
  margin: 30px auto;
}
.team-psycologiest-box-conatiner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  /* display: flex; */
}
.psycologiest-box-inside h1,
.psycologiest-box-inside p,
.psycologiest-box-inside span {
  padding: 20px;
}
.follw-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  font-size: 20px;
}
.follow-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-caro-display {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-addiction-txt-inside button {
  padding: 10px 25px;
  border: none;
  border-radius: 20px;
  color: white;
  background-color: black;
}
.team-psychologist-box-conatiner-main{
  display: flex;
  align-items:center ;
  justify-content: center;
  margin: auto;
}
@media screen and (max-width: 600px) {
  .team-psychologist-box-conatiner {
    grid-gap: 2rem;
    display: grid;
    place-items: center;
    gap: 2rem;
    grid-template-columns: repeat(1, 0.2fr);
    margin: 20px;
}
  .team-psycologiest-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffdd7e;
    width: 450px;
    height: 650px;
    border-radius: 30px;
    border: 2px solid black;
    margin: 30px auto;
  }
    .team-caro-display {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14%;
    }
  .mobile-addiction-txt-inside p {
    font-size: 10px;
    /* color: red; */
  }
  .team-psycologiest-box-conatiner {
    margin: auto;
    grid-template-columns: repeat(1, 1fr);
  }
  .team-psycologiest-box {
    display: flex;
    flex-direction: column;
  }
  .Team-card-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 5rem; */
    background-color: #bae8ac;
    width: 291px;
    z-index: 9999;
    border-radius: 20px;
    height: 203px;
    padding: 20px;
  }
  .team-psycologiest-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffdd7e;
    width: 274px;
    height: 488px;
    border-radius: 30px;
    border: 2px solid black;
    margin: 30px auto;
  }
  .team-psycologiest-box img {
    width: 80%;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .team-psychologist-box-conatiner {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 0.2fr);
    margin: 20px;
}
.team-psycologiest-box-conatiner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    /* display: flex; */
}
}