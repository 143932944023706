.password {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.password input {
  width: 80px;
  height: 40px;
  text-align: center;
  background-color: transparent;
  border: none;
  border-bottom: solid 2px black;
  font-size: 20px;
  /* color: white; */
  outline: none;
}
.password input:hover {
  border-bottom: solid 2px red;
}

@media screen and (max-width: 600px){

  .password input {
    width: 45px;
    height: 40px;
    text-align: center;
    background-color: transparent;
    border: none;
    border-bottom: solid 2px black;
    font-size: 20px;
    /* color: white; */
    outline: none;
}
}
