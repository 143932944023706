.down-arrow {
    position: absolute;
    width: 9%;
    margin-left: -29%;
    margin-top: 13%;
}
.hiring-boxes {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}
.flip-card {
  background-color: transparent;
  width: 390px;
  height: 454px;
  perspective: 1000px;
  font-family: sans-serif;
}
.flip-card p,
.title {
  margin-top: 25px;
}
.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.flip-card-back button {
  background-color: #ffdd7e;
  padding: 10px 40px;
  border: none;
  border-radius: 20px;
  margin: auto;
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* border: 1px solid coral; */
  border-radius: 1rem;
}

.flip-card-front {
  background: #ffdd7e;
  /* color: coral; */
}

.flip-card-back {
  background: black;
  color: white;
  transform: rotateY(180deg);
}
@media screen and (max-width: 600px) {
   .down-arrow {
    position: absolute;
    width: 16%;
    margin-left: -64%;
    margin-top: 30%;
}
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 500px;
    perspective: 1000px;
    font-family: sans-serif;
  }
}
