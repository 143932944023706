.Ngoandpsycologist-top-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}
.Ngoandpsycologist-top-btns button{
    background-color: #ffdd7e;
    padding: 10px 30px;
    border: none;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
    
}
