.green-information {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bae8ac;
  padding: 15px;
  color: black;
  /* font-weight: 700; */
  border-radius: 10px;
}
.hiring-form-holeconatiner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-form-hiring {
  background-color: #bae8ac;
  border-radius: 20px;
  padding: 10px;
}
.full-form-hiring p{
  font-family: "Questrial", sans-serif; 
}
.form-first {
  margin-top: 50px;
}
.input-coverletter{
  padding: 10px;
}
.down-arrow-conatiner {
    margin: 0px 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.down-arrow-conatiner p{
   font-family: "Questrial", sans-serif;
  font-size: 18px;
  margin: 10px;
  text-decoration: none;
}
  .hiring-form-main{
    height: 150vh;
  }
@media screen and (max-width: 600px) {
  .hiring-form-holeconatiner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 130px;
  }
  .hiring-form-main {
    height: 135vh;
  }
     .form-position {
        background-color: #bae8ac;
        padding: 15px;
        color: black;
        font-weight: 700;
        border-radius: 10px;
        /* width: 200%; */
        /* position: absolute; */
        
    }
    .container-form form {
    position: relative;
    height: auto;
}
  .hiring-form-main{
    height: 150vh;
  }
}
@media (min-width: 600px) and (max-width: 1024px){
  .hiring-form-main{
    height: 150vh;
  }
}
