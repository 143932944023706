
.container-speek {
  position: relative;
  text-align: center;
  color: white;
}
.container-speek img {
  width: 70%;
}
.speakeasy-age-video {
        width: 70%;
        border-radius: 20px;
        margin-left: -50px;
    }
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered h1 {
  font-family: Relo;
  font-weight: 900;
  font-size: 40px;
  color: black;
}
.iframe-for-speekeassy {
  margin-top: 10%;
  border-radius: 30px;
  width: 300px;
  height: 200px;
  margin-left: -10%;
}
.centered h2 {
  font-family: myFont;
  color: #256c55;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
  margin-top: -4%;
}
.para p {
  width: 100%;
  margin: auto;
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
}
.speektop-icon {
  /* color: red; */
  font-size: 30px;
  margin-left: 20%;
  margin-top: -25%;
  position: absolute;
}
@media screen and (max-width: 600px) {
    .para p {
        width: 144%;
        margin: auto;
        font-family: "Questrial", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: black;
        /* margin-top: 20%; */
        font-size: 10px;
        margin-left: -26%;
    }
  .speektop-icon {
    /* color: red; */
    font-size: 30px;
    margin-left: 69%;
    margin-top: -90%;
    position: absolute;
  }
  .container-speek img {
    width: 97%;
}
    .iframe-for-speekeassy {
  margin-top: 10%;
  border-radius: 10px;
  width: 80%;
  height: 100px;
  margin-left: -10%;
}
.speakeasy-age-video {
    width: 100%;
    border-radius: 20px;
    margin-left: -26px;
}
.container-speek-a img {
    width: 100%;
    min-height: 164px;
    margin-top: -2%;
    /* margin-left: -24%; */
}
}
@media (min-width: 600px) and (max-width: 1024px) {
  .speektop-icon {
    /* color: red; */
    font-size: 30px;
    margin-left: 39%;
    margin-top: -47%;
    position: absolute;
  }
  .iframe-for-speekeassy {
  margin-top: 10%;
  border-radius: 30px;
  width: 300px;
  height: 150px;
  margin-left: -10%;
}
.speakeasy-age-video {
        width: 67%;
        border-radius: 20px;
        margin-left: -20px;
    }
    .container-speek-a img {
    width: 161%;
    min-height: 300px;
    margin-top: -36%;
    margin-left: -24%;
}
.para p {
    width: 500px;
    margin: auto;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: black;
margin-top: -10%;
}
.container-speek-a img {
    width: 161%;
    min-height: 300px;
    margin-top: -23%;
    margin-left: -24%;
}
}
