.recover-main-container {
  height: auto;
}
.recover-topimage-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: -50px;
}
.recover-pass {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: -10%;
}
.recover-pass-otp{
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: -10%;
}
.recover-topimage-container img:nth-child(1) {
  /* position: absolute; */
  /* background-color: red; */
  width: 250px;
  margin-top: 3px;
}
.recover-topimage-container img:nth-child(2) {
  /* position: absolute; */
  /* background-color: red; */
  width: 70px;
  margin-top: -30px;
  margin-right: 10%;
}
.recover-bottomimage-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.recover-bottomimage-container img:nth-child(1) {
  /* position: absolute; */
  /* background-color: red; */
  width: 70px;
  margin-left: 130px;
}
.recover-bottomimage-container img:nth-child(2) {
  /* position: absolute; */
  /* background-color: red; */
  width: 270px;
  margin-top: -110px;
  /* margin-right: -5px; */
}
.recover-bottomimage-container img:nth-child(3) {
  position: absolute;
  /* background-color: red; */
  width: 750px;
  margin-left: 50%;
  margin-top: -44%;
  z-index: -1;
}
.recover-pass img:nth-child(1) {
  /* background-color: red; */
  position: absolute;
  margin-left: -20%;
}
.recoverpass-heading p {
  font-family: myFont;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  /* margin-left: 24px;
  margin-top: -40px; */
}
.recoverpass-heading h1 {
  font-family: Relo;
  font-weight: 900;
  font-size: 32px;
  margin-top: -15%;

}
.recoverpass-heading h2 {
  font-family: myFont;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
  margin-top: -10%;
  /* margin-left: 50px; */
  color: #256c55;
}
.recoverpass-heading{
  text-align: center;
}
.recover-pass form {
  /* border: 2px solid black; */
  margin-top: -10px;
  padding: 20px;
}
.pass-sub {
  display: grid;
  place-items: center;
}
.pass-sub button {
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  background-color: #ffdd7e;
}
.email-in label {
  font-size: 18px;
  font-family: "Questrial", sans-serif;
}
.email-in input {
  width: 300px;
  height: 40px;
  border: 1px solid green;
  border-radius: 5px;
  margin-top: 10px;
}
.email-in input::placeholder {
  color: black;
  font-weight: 600;
  margin: 30px;
  padding: 30px;
}
.email-in span {
  color: brown;
}
.recoverpass-heading img {
  margin-left: 30px;
}

@media screen and (max-width: 600px) {
  .recover-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 5%;
}
  .recover-bottomimage-container img:nth-child(3) {
    position: absolute;
    /* background-color: red; */
    width: 320px;
    margin-left: 17%;
    margin-top: -44%;
    z-index: -1;
  }
  .recover-bottomimage-container img:nth-child(1) {
    /* position: absolute; */
    /* background-color: red; */
    width: 70px;
    margin-left: 50px;
  }
  .recover-topimage-container img:nth-child(1) {
    /* position: absolute; */
    /* background-color: red; */
    width: 157px;
    margin-top: 40px;
  }
  .recover-pass img:nth-child(1) {
    /* background-color: red; */
    position: absolute;
    margin-left: -58%;
  }
  .recover-bottomimage-container img:nth-child(2) {
    /* position: absolute; */
    /* background-color: red; */
    width: 270px;
    margin-top: -17px;
    /* margin-right: -5px; */
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .recover-bottomimage-container img:nth-child(3) {
    position: absolute;
    /* background-color: red; */
    width: 750px;
    margin-left: 2%;
    margin-top: -34%;
    z-index: -1;
  }
  .recover-pass img:nth-child(1) {
    /* background-color: red; */
    position: absolute;
    margin-left: -37%;
  }
}
