.Success-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}
 .greeting{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;
    position: relative;
 }
    .greeting img{
        /* background-color: #256c55; */
    width: 20%;
}
    .Success-main-container img:nth-child(3){
        /* background-color: red; */
    width: 100%;
    position: absolute;
}
    .Success-main-container img:nth-child(2){
        /* background-color: red; */
    width: 100%;
    position: absolute;
    z-index: -1;
}
    .Success-main-container p{
       font-family: myFont;
    color: #256c55;
    font-size: 60px;
}
    .Success-main-container h1{
    font-size: 60px;
    margin: 10px;
    font-family: Blacky;
    letter-spacing: 0.1rem;
    }
    .bush{
        position: absolute;
    }
    .color-for-success{
        display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    }
  