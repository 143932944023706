.login-btm {
  margin-top: 80px;
}
.input-otp input {
    width: 80px;
    height: 40px;
    text-align: center;
    background-color: transparent;
    border: none;
    border-bottom: solid 2px black;
    font-size: 20px;
    /* color: white; */
    outline: none;
        margin-left: 15px;
}
.other-signup{
  margin: 30px;
}
.otp-form{
  /* display: flex; */
}
.otp-verfiy-submit{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.otp-verfiy-submit button{
  background-color: #ffdd7e;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;

}
.otp-verfiy-container{
  margin-top: 10%;
}
.otp-verfiy-submit button:hover{
   background-color:white ;
  color: #ffdd7e ;
  border: 2px solid #ffdd7e;
}
.error-input {
  animation: shake 0.3s forwards;
  animation-iteration-count: 4;
  border: 2px solid red;
  /* background-color: #256c55; */
}
.log-txt{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@keyframes shake {
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX-(5px);
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .login-btm {
    margin-top: 80px;
  }
  .other-signup img {
    width: 400px;
    margin-top: 33%;
    margin-left: 54%;
    z-index: -1;
  }
}
