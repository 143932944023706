.categories-main-holecontainer {
  background-image: url("../Images/ALHeaderbg.png");
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  z-index: 1;
}
.Hoodi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.categories-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cate-yellow-box {
  width: 40%;
}
.cate-left-box {
  /* background-color: red; */
  padding: 30px;
}
.cate-left-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0rem;
  width: 100%;
}
.cate-left-box button {
  width: 30%;
  padding: 10px;
  margin: auto 70%;
  background-color: #ffdd7e;
  border: 1px solid black;
  border-radius: 50px;
  font-family: Relo;
  font-weight: 900;
  font-size: 16px;
}
.Header-video{
  width: 80%;
  border-radius: 20px;
  margin: 30px;

}
.cate-left-box h2 {
  /* color: red; */
  padding-bottom: 20px;
  font-family: Relo;
  font-weight: 900;
  font-size: 20px;
}
.cate-left-box img {
  width: 50px;
  padding-bottom: 30px;
}
.box1,
.box2,
.box3,
.box4 {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.cate-video{
  width: 100%;
  border-radius: 20px;
  height: 300px;
  margin-left: 10%;
}
@media screen and (max-width: 600px) {
  /* .cate-left-box {
    margin-top: -36%;
} */
.imsha{
  margin-top: 15%;
}
  .categories-main-holecontainer {
    background-image: url("../Images/Bigbg.png");
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    position: relative;
  }
  .categories-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 5%; */
  }
  .cate-yellow-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: none;
    width: 85%;
    height: auto;
    border-radius: 0px 50px 50px 5px;
  }
  .cate-left-box button {
    width: 130%;
    padding: 10px;
    margin: auto 45%;
    background-color: #ffdd7e;
    border: 1px solid black;
    border-radius: 50px;
    font-family: Relo;
    font-weight: 900;
    font-size: 16px;
  }
  .cate-left-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0rem;
    width: 100%;
}
  .cate-video {
    width: 100%;
    border-radius: 20px;
    height: 192px;
    margin-left: 10%;
}
}
@media (min-width: 600px) and (max-width: 1024px) {
  .cate-left-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0rem;
    width: 100%;
}
  .cate-video {
    width: 100%;
    border-radius: 20px;
    height: 159px;
    margin-left: 10%;
    margin-top: 30px;
}
  .categories-main-holecontainer {
    background-image: url("../Images/ALHeaderbg.png");
    width: 100%;
    height:auto;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
  }
  .Hoodi h1 {
    margin: 20px;
  }
  .Hoodi img {
    width: 173%;
    margin-left: 42%;
    justify-content: flex-start;
  }
  .cate-left-box button {
    width: 70%;
    padding: 10px;
    margin: auto 70%;
    background-color: #ffdd7e;
    border: 1px solid black;
    border-radius: 50px;
    font-family: Relo;
    font-weight: 900;
    font-size: 16px;
  }
  .cate-left-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0rem;
    margin: 30px;
  }
  .cate-yellow-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: none;
    /* width: 107%; */
    height: 65px;
    border-radius: 0px 50px 50px 5px;
  }
  .categories-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 10%;
}
}
/* @media screen and (max-width: 599px){
.cate-left-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    background-color: red;
}
} */