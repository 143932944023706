.card-inneR {
    position: relative;
    height: 7cm;
    /* margin-top: 9px; */
    border-radius: 20px;
    width: 100%;
}
.Eealyage-headrer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin: 5% auto;
}
.card-inneR play-button {
  height: 2cm;
}
.MobileAddiction-yt-video {
  margin-top: 1%;
  border-radius: 30px;
  width: 500px;
  height: 300px;
  margin-left: 1%;
  /* background-color: rebeccapurple; */
}
.adaptation-video{
  width: 30%;
  border-radius: 10px;
  box-shadow: 18px 17px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
}
.early-age-video {
    width: 50%;
    border-radius: 20px;
    margin-left: -25px;
}
.card-inneR p,
.card-inneR h1 {
  position: relative;
  z-index: 3; /* Ensure the text is above the image */
}

.card-inneR p {
  font-family: myFont;
  color: #256c55;
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  margin-top: -3%;
}
.card-inneR h1 {
  font-family: Relo;
  font-weight: 900;
  font-size: 30px;
}
.buttonss button {
  background-color: #ffdd7e;
  padding: 15px;
  margin: 2% auto;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 2cm;
}
.getyoukow-container {
  background-color: #ffdd7e;
  width: 75%;
  height: 300px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.getyoukow-container img{
  width: 250px;
  border-radius: 20px;
}
.getyoukow-container button{
  background-color: black;
  border: none;
  border-radius: 20px;
  padding: 10px 20px ;
  margin-top: 10px;
  color: white;
}
.getyoukow-text-recomended{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 50%;
  text-align: left;
  margin: 10px;
}
.getyoukow-text-recomended p{
  color: #CD4631;
}
.getyoukow-container img:nth-child(2){
 width: 120px;
    /* background-color: #256c55; */
    margin-left: 70%;
    position: absolute;
    margin-top: -155px;
}
.getyoukow-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 30px;
}
/* Remaining styles for background image */
.card-inneR {
  /* position: absolute; */
  background-color: #bae8ac;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.card-inneR img {
  width: 60%;
  margin-left: 5%;
  margin-top: -17%;
  z-index: 3;
  /* Ensure the image is above the background image */
}

/* goaal vision .............................................. */
.card-inneR-text {
    margin: 30px;
    width: 41%;
}
.green-bourd-Earlyage {
  /* margin: 50px; */
  position: relative;
  display: grid;
  place-items: center;
}
.getyouknow-image img {
  width: 250px;
  height: 177px;
  margin: 24px;
  aspect-ratio: 3 / 2;
  border-radius: 59px;
  object-fit: contain;
}
/* .getyoukow-image-red {
  margin-top: -33%;
  margin-left: -11%;
  position: absolute;
  width: 16%;
} */
.getyouknow-txt {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
  position: relative;
}
.getyouknow-txt button {
  background-color: black;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 20px;
}
.getyouknow-txt h1 {
  text-align: center;
  font-size: 20px;
  width: 80%;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .MobileAddiction-yt-video {
  /* margin-top: 10%; */
  border-radius: 10px;
  width: 60%;
  height: 250px;
  margin-left: -10%;
}
  .card-inneR {
    /* position: absolute; */
    background-color: #bae8ac;
    display: flex;
    align-items: center;
    justify-content: center;
  }
/* .getyoukow-image-red {
    margin-top: -30%;
    margin-left: 79%;
    position: absolute;
    width: 13%;
} */
  .card-inneR img {
    width: 56%;
    margin-left: -4%;
    margin-top: 3%;
  }
  .getyouknow-image img {
    width: 300px;
  }
  .getyouknow-txt {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    /* position: relative; */
  }
  .getyoukow-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 30%;
  }
  .goalvision-headrer-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 8%;
  }
  .card-inneR {
    background-color: #bae8ac;
  }
  .Eealyage-headrer-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 30%;
    padding: 30px;
  }
  .getyoukow-container {
    background-color: #ffdd7e;
    width: 90%;
    height: 300px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
@media screen and (max-width: 600px) {
  .early-age-video {
    width: 50%;
    border-radius: 20px;
    margin-left: -60px;
}
    .MobileAddiction-yt-video {
  /* margin-top: 10%; */
  border-radius: 30px;
  width: 200px;
  height: 150px;
  margin-left: -10%;
  background-color: red;
}
.getyoukow-container img:nth-child(2) {
    width: 99px;
    /* background-color: #256c55; */
    margin-left: 70%;
    position: absolute;
    margin-top: -244px;
}
  .getyoukow-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    /* margin: 5px; */
  }
  .getyouknow-image img {
    width: 250px;
  }
  .getyouknow-txt {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    /* position: relative; */
  }
  .getyoukow-container {
    background-color: #ffdd7e;
    width: 65%;
    height: 362px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
  }
  .getyoukow-container img {
    width: 130px;
    border-radius: 20px;
}

.getyoukow-text-recomended h1{
  font-size: 20px;
}
.card-inneR {
    /* position: absolute; */
    background-color: #bae8ac;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    width: 85%;
    height: 185px;
    margin: auto;
}
.card-inneR-text{

}
  .card-inneR h1 {
    font-family: Relo;
    font-weight: 900;
    font-size: 21px;
    margin-left: 1%;
  }
     .card-inneR p {
        font-family: myFont;
        color: #256c55;
        font-weight: 400;
        font-style: normal;
        font-size: 29px;
        /* margin-top: -4%; */
        /* margin-left: 2px; */
    }
  .card-inneR img {
    width: 57%;
    margin-left: -10%;
    margin-top: 7%;
    border-radius: 0px 0px 16px 0px;
  }
}



.adaptationToMobile-yt-video{
  margin-top: 1%;
  border-radius: 30px;
  width: 500px;
  height: 300px;
  margin-left: 1%;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .adaptationToMobile-yt-video {
  /* margin-top: 10%; */
  border-radius: 10px;
  width: 60%;
  height: 250px;
  margin-left: -10%;
}
.getyoukow-container img {
    width: 180px;
    border-radius: 20px;
    margin: 20px;
}
}

@media screen and (max-width: 600px) {
  .adaptationToMobile-yt-video {
/* margin-top: 10%; */
border-radius: 30px;
width: 200px;
height: 150px;
margin-left: -10%;
background-color: red;
}
}


