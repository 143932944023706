.Side-Bar {
  display: flex;
  flex-direction: column;
  height: 104vh;
  /* border: 1px solid black; */
  width: 20%;
  position: relative;
  margin-top: -1%;
}
.adminpanel-main-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}
.adminpanel-route {
  height: auto;
  /* border: 1px solid red; */
  width: 100%;
  background-color: #f3f3f3;
  margin-top: 8%;
}
.adminpanel-route-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* border: 1px solid yellow; */
  width: 80%;
  /* background-color: #f3f3f3; */
  position: relative;
}
.admin-navbar {
  height: 11vh;
  /* border: 1px solid red; */
  width: 90%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  z-index: 3;
}
.admin-top-navbar {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 20%;
}
.psycologist-box-admint{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20%;
}
.admin-top-navbar-img img {
  width: 150px;
}
.admin-top-navbar-img img:nth-child(2) {
  /* background-color: red; */
  width: 106%;
  height: 50px;
  margin-top: 83px;
  position: absolute;
}
.admin-top-navbar-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #bae8ac;
  width: 100%;
  height: 90px;
  margin-top: -7px;
  position: relative;
}
.admin-ul-list {
  list-style: none;
  max-height: 620px;
  overflow-y: auto;
}
.admin-ul-list li {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1rem;
}
.admin-ul-list li:hover {
  background-color: #bae8ac;
}
.Links-for-admin {
  text-decoration: none;
  color: black;
  font-size: 19px;
  font-family: "Questrial", sans-serif;
}
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 190px;
}

.input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
}

.input::placeholder {
  color: #9e9ea7;
}

.input:focus,
input:hover {
  outline: none;
  border-color: rgba(247, 127, 0, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(247 127 0 / 10%);
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}
