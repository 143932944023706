.popup{
    background-color: bisque;
    width: 100%;
    height: 400px;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    border-radius: 10px;
}
.popup input{
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
}