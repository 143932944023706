.music-player {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.music-player button {
  background: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .home-main {
    overflow: hidden;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .home-main {
    overflow: hidden;
  }
}
