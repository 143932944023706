.getintouch-adimn-top-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}
.getintouch-adimn-top-btn button{
    background-color: #ffdd7e;
    padding: 10px 30px;
    border: none;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;

}
.getintouch-adimn-top-btn button:hover{
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}
.getintouch-top{
    background-color: white;
    width: 80%;
    height: 100px;
    margin: 50px auto;
    border-radius: 20px;
     display: flex;
  align-items: center;
  justify-content: center;
}
.search {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  text-align: center;
}

.search__input {
  font-family: inherit;
  font-size: inherit;
  background-color: #f4f2f2;
  border: none;
  color: #646464;
  padding: 0.7rem 1rem;
  border-radius: 30px;
  width: 12em;
  transition: all ease-in-out .5s;
  margin-right: -2rem;
}

.search__input:hover, .search__input:focus {
  box-shadow: 0 0 1em #00000013;
}

.search__input:focus {
  outline: none;
  background-color: #f0eeee;
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus + .search__button {
  background-color: #f0eeee;
}

.search__button {
  border: none;
  background-color: #f4f2f2;
  margin-top: .1em;
}

.search__button:hover {
  cursor: pointer;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: #b4b4b4;
}
.no-data{
    display: flex;
    align-items: center;
    justify-content: center;
}
.getintouch-form{
    width: 80%;
    margin: auto;
}