.getintouch-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10%;
}
.get-form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    background-color: #bae8ac;
    width: 64%;
    min-height: 500px;
    margin-left: -2%;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: -14%;
}
.get-form span{
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
     font-family: "Questrial", sans-serif;

}

.form-inputs {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: -5px;
}
.form-inputs input {
    padding: 10px;
    width: 128%;
    border-radius: 10px;
    /* margin: 10px auto; */
    border: 1px solid black;
}


/* .form-inputs input:nth-child(2) {
  margin-left: 20px;
} */
.form-inputs input:nth-child(3) {
}
.getintouch-msg input {
    border-radius: 10px;
    padding: 20px;
    width: 188%;
    height: 120px;
    margin-left: 6%;
    margin-top: 15px;
}
.error-getin-touch label {
    text-align: left;
    font-size: 20px;
    /* margin: 10px; */
    margin-left: -115px;
    margin-bottom: 10px;
}
.getintouch-msg label{
    text-align: left;
    font-size: 20px;
    margin-bottom: 40px;
    margin-left: -50%;
}
.getintouch-msg{
  margin-top: 20px;
}
.agree-get {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 15px auto;
}
.agree-get button {
  background-color: black;
  color: white;
  padding: 10px 50px;
  border-radius: 20px;
}
.get-form img {
    position: absolute;
    width: 47%;
    margin-left: -108%;
    margin-top: -74%;
}

.get-form p {
 font-family: myFont;
    color: #256c55;
    font-weight: 400;
    font-style: normal;
    font-size: 60px;
    /* margin-top: -9%; */
}

.get-form h1 {
    font-family: Blacky;
    letter-spacing: 0.1px;
    font-weight: 900;
    font-size: 62px;
    /* margin-top: 6%; */
    /* text-shadow: 4px 6px 11px rgba(0, 0, 0, 0.6); */
}
.error-getin-touch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .agree-get {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 18% auto;
}
  .error-getin-touch label {
    text-align: left;
    font-size: 20px;
    /* margin: 10px; */
    margin-left: -61px;
    /* margin-bottom: -1px; */
    margin-top: 11px;
}
      .get-form img {
        position: absolute;
        width: 43%;
        margin-left: 92%;
        margin-top: -172%;
    }
  .form-inputs {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        margin: auto -11%;
    }
  .get-form img {
        position: absolute;
        width: 52%;
        margin-left: 56%;
        margin-top: -618px;
        border-radius: 0px 0px 41px 73px;
    }
  .get-form {
    /* background-color: red; */
    width: 90%;
    margin-bottom: 20px;
  }
  .form-inputs input {
    padding: 10px;
    width: 91%;
    border-radius: 10px;
    margin: 10px auto;
  }
  .get-form {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        background-color: #bae8ac;
        width: 85%;
        height: 800px;
        margin-left: 3%;
        padding: 10px;
        border-radius: 20px;
        margin-bottom: -3%;
    }
    .getintouch-msg label {
    text-align: left;
    font-size: 20px;
    margin-bottom: 40px;
    margin-left: -22%;
}
.getintouch-msg input {
        border-radius: 10px;
        padding: 20px;
        width: 87%;
        height: 158px;
        margin-left: 18%;
        margin-top: 15px;
    }
    .get-form h1 {
    font-family: Blacky;
    letter-spacing: 0.1px;
    font-weight: 900;
    font-size: 38px;
    /* margin-top: 6%; */
    /* text-shadow: 4px 6px 11px rgba(0, 0, 0, 0.6); */
} 
.get-form p {
    font-family: myFont;
    color: #256c55;
    font-weight: 400;
    font-style: normal;
    font-size: 43px;
    margin-top: -9%;
}
}
/* .agree-get button{
  background-color: #FFDD7E;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  
} */
@media (min-width: 600px) and (max-width: 1024px) {
      .form-inputs input {
        padding: 10px;
        width: 100%;
        border-radius: 10px;
        /* margin: 10px auto; */
    }
  .getintouch-msg input {
    border-radius: 10px;
    padding: 20px;
    width: 188%;
    height: 120px;
    margin-left: 24%;
    margin-top: 15px;
}
      .form-inputs input {
        padding: 10px;
        width: 134%;
        border-radius: 10px;
        /* margin: 10px auto; */
    }
      .get-form img {
        position: absolute;
        width: 48%;
        margin-left: -88%;
        margin-top: -75%;
    }
 .get-form {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        background-color: #bae8ac;
        width: 87%;
        height: 537px;
        margin-left: 3%;
        padding: 10px;
        border-radius: 20px;
        margin-bottom: -0%;
        z-index:3;
    }
     .psycologist-image-part{
  display: flex;
  align-items: center;
  justify-content: center;
 }
}

