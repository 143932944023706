.boxtop-tems img {
  width: 30%;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.slick-next:before, .slick-prev:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: red;
    font-family: slick;
    font-size: 20px;
    line-height: 1;
    opacity: .75;
}
.boxtop-tems {
  margin: 10px;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 20%; */
}
.posdcast-video {
    width: 50%;
    margin: 10px;
    border-radius: 20px;
}
.postcast-txt{
  margin: 10px;
}
.sum {
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 44%;
  height: 305px;
  border: 2px solid black;
  border-radius: 20px;
  margin-bottom: 40px;
}
.card___wrapper-acounts {
  display: flex;
}
.podcast-yt-video{
  border-radius: 20px;
  margin: 20px;
}
.searchButton {
  color: white;
  position: absolute;
  right: 8px;
  width: 100px;
  height: 50px;
  border-radius: 30px;
  background: #ffdd7e;
  padding: 10px 20px;
  border: 0;
  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
/*hover effect*/

.searchInput {
  border: none;
  background: none;
  outline: none;
  color: black;
  font-size: 15px;
  padding: 24px 46px 24px 26px;
}
.searchBox {
    display: flex;
    max-width: 730px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    /* background: #2f3640; */
    border: 1px solid #ffdd7e;
    border-radius: 50px;
    position: relative;
    margin-top: -11%;
    margin-left: 30%;
    margin-bottom: 4%;
}
.potcast-category button {
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
}
.searchButton {
  color: white;
  position: absolute;
  right: 8px;
  width: 100px;
  height: 50px;
  border-radius: 30px;
  background: #ffdd7e;
  padding: 10px 20px;
  border: 0;
  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
/*hover effect*/

.searchInput {
  border: none;
  background: none;
  outline: none;
  color: black;
  font-size: 15px;
  padding: 24px 46px 24px 26px;
}
.boxtop-tems-inside {
  display: flex;

  /* flex-wrap: wrap; */
}
.boxtop-tems-inside h1 {
  font-size: 26px;
}
.potcast-category {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card__acounts img {
  border-radius: 50%;
}
.card__acounts img:nth-child(2) {
  /* background-color: red; */
  margin-left: -30%;
}
.product-list-nav {
  width: 70%;
  margin: 1% auto;
}
.potcaste-bottom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}
.potcaste-bottom-btn button {
  background-color: #ffdd7e;
  border: none;
  padding: 10px 40px;
  border-radius: 20px;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .posdcast-video {
    width: 45%;
    border-radius: 20px;
    margin: 20px;
}
  .searchBox {
        display: flex;
        max-width: 550px;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        /* background: #2f3640; */
        border: 1px solid #ffdd7e;
        border-radius: 50px;
        position: relative;
        margin-top: -250px;
        margin-left: 0%;
        margin-bottom: 0%;
    }
  .product-list {
    display: flex;
    flex-wrap: wrap;
    margin: 33px;
        gap: 1rem;
  }
  .card__acounts img:nth-child(2) {
    /* background-color: red; */
    margin-left: 35%;
    margin-top: -67%;
  }
  .boxtop-tems img {
    width: 35%;
  }
  .potcast-category button {
background-color: white;
    border-radius: 20px;
    margin-top: 3%;
  }
  .potcast-category {
    display: flex;
    align-items: center;
    /* margin-top: -16px; */
    justify-content: space-around;
    /* flex-wrap: wrap; */
  }
  .podcast-yt-video{
    width: 320px;
    height: 180px;
  }
  .sum {
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 48%;
    height: 436px;
    border: 2px solid black;
    border-radius: 20px;
    margin-bottom: 40px;
}
.boxtop-tems-inside {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
}
.searchubar-for-podcast{
  margin-left: 19%;
}

}
@media screen and (max-width: 600px) {
  .posdcast-video {
    width: 95%;
    margin: 10px;
    border-radius: 20px;
}
  .card__acounts img:nth-child(2) {
    /* background-color: red; */
    margin-left: -30%;
    margin-top: -87%;
}
  .podcast-yt-video{
    width: 280px;
    margin-left: 10px;
  }
  .product-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
.searchBox {
        display: flex;
        max-width: 436px;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        /* background: #2f3640; */
        border: 1px solid #ffdd7e;
        border-radius: 50px;
        position: relative;
        margin-top: 11%;
        margin-left: 5%;
        margin-bottom: 4%;
    }
  .sum {
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 83%;
        height: 391px;
        border: 2px solid black;
        border-radius: 20px;
        margin-bottom: 40px;
    }
       .card-inner {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        margin: 17px auto;
        justify-content: center;
        width: inherit;
        height: inherit;
        /* background-image: url(../Images/Arrow.png); */
        background: #bae8ac;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-radius: 8px;
        width: 88%;
        height: auto;
    }
    .green-bourd-grief {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .boxtop-tems-inside {
    display: flex;
    flex-wrap: wrap;
}
.potcast-category {
    display: flex;
    align-items: center;
    gap: 1rem;
    /* margin-top: -20px; */
    justify-content: space-around;
    flex-wrap: wrap;
}
}
