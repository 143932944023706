.hiring-top-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}
.hiring-top-btns button{
    background-color: #ffdd7e;
    padding: 10px 30px;
    border: none;
    font-size: 16px;            
    border-radius: 20px;
    cursor: pointer;
    
}
.revenvue-container{
    width: 90%;
    margin: auto;
    border-radius: 10px;
}
