.affiliate-program-heading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: -1;
}
.affiliate-program-heading img{
  width: 250px;
}
.affiliate-program-heading img:nth-child(1) {
  /* background-color: black; */
  position: absolute;
  /* float: left; */
  margin-left: -1242px;
  z-index: -9999;
  width: 500px;
  
}
.inside-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.inside-button button {
  padding: 10px 25px;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  background-color: #ffdd7e;
}
.inside-button button:hover {
  -webkit-box-shadow: -1px 8px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 8px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 8px 5px 0px rgba(0, 0, 0, 0.75);
  transition: 0.5s;
}
.inside-button img {
  background-color: #ffdd7e;
  border-radius: 50%;
  width: 50px;
  position: absolute;
  margin-left: 160px;
  border: 2px solid white;
}
.psycologist-part {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../Images/Smallpickbg.png");
  width: 100%;
  height: 118vh;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  gap: 10rem;
}
.Clg-graduate-part {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 92%;
    height: auto;
    margin: auto;
}
.Clg-how-can-join-box img{
  width: 100px;
}
.NGO-part {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* position: relative; */
  background-image: url("../Images/Smallpickbg.png");
  width: 100%;
  height: 118vh;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
}
.psycologist-image-part img{
  width: 360px;
}
.psycologist-text-part {
  /* width: 50%; */
}
/* .psycologist-how-can-join{
  margin-right: 20%;
} */
.cls-gratuate-image-part img {
    width: 404px;
    margin-left: -70%;
    margin-top: 245px;
}
.NGO-image-part{
width: 20%;
}
.NGO-image-part img{
width: 260px;
}
.psycologist-how-can-join-box {
    height: 100px;
    width: 600px;
    max-width: 303px;
    background-color: #bae8ac;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.getin-touch-affilate {
  height: 700px;
  width: 60%;
  background-color: #bae8ac;
  border-radius: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .getin-touch-affilate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
} */
.Clg-how-can-join-box {
    height: 100px;
    width: 279px;
    background-color: #ffdd7e;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.psycologist-how-can-join-box-container {
    display: grid;
    grid-template-columns: repeat(2, 0.1fr);
    gap: 1rem;
}
.clg-how-can-join-box-container {
  display: grid;
  grid-template-columns: repeat(2, 0.3fr);
  gap: 2rem;
}
.getin-touch-affilate-form {
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  gap: 2rem;
}
.getin-touch-affilate-main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.getin-touch-affilate-main img:nth-child(1) {
  /* background-color: red; */
  position: absolute;
  margin-left: -79%;
  margin-top: 30%;
  width: 350px;
}
.enga-main {
  margin-bottom: 50px;
}
.getin-touch-affilate-main img:nth-child(3) {
  /* background-color: yellow; */
  position: absolute;
  margin-left: 80%;
  margin-top: -25%;
  width: 300px;
}
.getin-touch-affilate-form input {
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
}
.message-getin-touch-affilate input {
  height: 100px;
  width: 400px;
  /* margin-left: 130px; */
}  .psycologist-image-part{
    width: 20%;
  }
  .cls-gratuate-image-part{
    width: 20%;
  }
  .clg-gratuate-text-part{
    width: 60%;
  }
.getin-touch-affilate-form label {
  font-size: 20px;
}
@media screen and (max-width: 600px) {

  .Clg-graduate-part,
  .NGO-part,
  .psycologist-part {
    flex-direction: column;
  }
  .affiliate-program-heading img {
    width: 120px;
    /* background-color: red; */
  }
    .psycologist-image-part img {
        width: 270px;
        margin-left: -85px;
    }
  .enagewith-conatiner {
    flex-wrap: wrap;
  }
    .psycologist-how-can-join-box-container {
        display: grid;
        grid-template-columns: repeat(1, 0.5fr);
        gap: 2rem;
        margin-left: 20px;
        place-items: center;
    }
    .psycologist-how-can-join-box-container-center{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
.aff-bix-imsha{
  margin-left: 15%;
}
  .getin-touch-affilate-form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0rem;
  }
  .getin-touch-affilate-main img:nth-child(3) {
    /* background-color: yellow; */
    position: absolute;
    margin-left: 51%;
    margin-top: -167%;
    width: 124px;
  }
  .getin-touch-affilate-main img:nth-child(1) {
    /* background-color: red; */
    position: absolute;
    margin-left: -67%;
    margin-top: 175%;
    width: 155px;
  }
  .message-getin-touch-affilate input {
    height: 100px;
    width: 264px;
    /* margin-left: 130px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
     .NGO-image-part img {
        width: 300px;
        margin-left: -111px;
    }
  .psycologist-part {
    height: auto;
  }
    .Clg-graduate-part {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: space-around;
        width: 100%;
        height: auto;
        margin-top: 2%;
        text-align: center;
    }
    .psycologist-part{
      text-align: center;
    }
       .cls-gratuate-image-part img {
        width: 277px;
        margin-left: -66px;
        margin-top: 0px;
    }
    .psycologist-how-can-join-box img{
      width: 80px;
    }
    .psycologist-part {
    gap: 1rem;
}
    .psycologist-how-can-join-box {
    height: 100px;
    width: 314px;
    background-color: #bae8ac;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
}
.pysco-imsha{
  margin-left: 40%;
}
  .NGO-part {
    height: auto;
    text-align: center;
  }
  .Clg-how-can-join-box {
    height: 100px;
    width: 301px;
    background-color: #ffdd7e;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.enage-box img {
    width: 230px;
    aspect-ratio: 3 / 6;
    object-fit: cover;
    margin-top: -96%;
    margin-left: -1%;
    /* margin: auto; */
    border-radius: 0px 10px 20px 20px;
}
  .enga-main {
    margin-bottom: 184px;
}
  .inside-button button {
    padding: 9px 23px;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    background-color: #ffdd7e;
}
.clg-how-can-join-box-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        margin-left: -12%;
    }
    .psycologist-imsha{
      margin-left: 35px;
    }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .psycologist-how-can-join{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cls-gratuate-image-part img {
    width: 465px;
    margin-left: -90%;
    margin-top: 74px;
}
  .clg-how-can-join-box-container {
    display: grid;
    grid-template-columns: repeat(2, 0.3fr);
    gap: 2rem;
}
  .NGO-text-part img{
    width: 100px;
  }
  .NGO-image-part img{
    width: 300px;
    margin-left: -97px;
  }
  /* .cls-gratuate-image-part img {
    width: 300px;
  } */
  .Clg-graduate-part,
  .NGO-part,
  .psycologist-part {
    flex-direction: column;
    gap: 1rem;
  }
    .psycologist-how-can-join-box {
        height: 90px;
        width: 300px;
        max-width: 268px;
        background-color: #bae8ac;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-left: 10px;
    }

    .Clg-how-can-join-box {
        height: 100px;
        width: 250px;
        background-color: #ffdd7e;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
  .affiliate-program-heading img {
    width: 200px;
    /* background-color: red; */
  }
  .affiliate-program-heading img:nth-child(1) {
    /* background-color: black; */
    position: absolute;
    /* float: left; */
    margin-left: -42%;
    width: 500px;
    margin-top: 200px;
  }
  .affiliate-program-heading {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    /* height: 55vh; */
}
  .psycologist-image-part img {
    width: 300px;
  }
  .psycologist-part {
    background-image: url("../Images/ALHeaderbg.png");
    height: auto;
    margin-bottom: 7%;
  }
  .NGO-part {
    background-image: url("../Images/ALHeaderbg.png");
    height: auto;
  }
  .getin-touch-affilate {
    height: 1015px;
    width: 94%;
  }
  .getin-touch-affilate-main img:nth-child(3) {
    /* background-color: yellow; */
    position: absolute;
    margin-left: 62%;
    margin-top: -81%;
    width: 300px;
  }
  .getin-touch-affilate-main img:nth-child(1) {
    /* background-color: red; */
    position: absolute;
    margin-left: -53%;
    margin-top: 103%;
    width: 350px;
  }
  .message-getin-touch-affilate input {
    height: 100px;
    width: 292px;
    margin-left: 60px;
  }
     .Clg-graduate-part {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: space-around;
        width: 100%;
        height: auto;
    }
}
