.problem-face-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 20px;
}
.problem-face-box img {
  width: 70%;
  border-radius: 20px 40px 20px 20px;
}
.best-solution {
  margin-top: 30px;
  background-image: url("../Images/Smallpickbg.png");
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: cover;
}
.get-help {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.get-help img:nth-child(2) {
  width: 50%;
}
.get-help img:nth-child(1) {
  /* background-color: red; */
  position: absolute;
  margin-left: -60%;
  z-index: -1;
}
.get-help img:nth-child(3) {
  /* background-color: green; */
  z-index: -1;
  position: absolute;
  margin-left: 60%;
}
@media screen and (max-width: 600px) {
}

@media (min-width: 600px) and (max-width: 1024px) {
  .best-solution {
    height: 45vh;
  }
  .get-help img:nth-child(3) {
    margin-left: 38%;
}
}
