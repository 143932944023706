.grief-main-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.grief-main-heading span {
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
  text-decoration: underline;
}
.radio-buttons-container {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 10px 6%;
}
.gs-icon {
  margin-left: 75%;
  /* margin-top: 10%; */
  font-size: 40px;
}
.radio-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.radio-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.radio-button__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.radio-button__custom {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #555;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.radio-button__input:checked + .radio-button__label .radio-button__custom {
  transform: translateY(-50%) scale(0.9);
  border: 5px solid black;
  color: yellow;
}

.radio-button__input:checked + .radio-button__label {
  color: black;
}

.radio-button__label:hover .radio-button__custom {
  transform: translateY(-50%) scale(1.2);
  border-color: yellow;
  box-shadow: 0 0 10px #4c8bf580;
}

.grief-main-heading h1 {
  font-family: Relo;
  font-weight: 900;
  font-size: 60px;
}
.grief-main-heading h2 {
  font-family: "Questrial", sans-serif;
  font-size: 20px;
}
.grief-main-heading p {
  font-family: myFont;
  color: #256c55;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  margin-top: -3%;
}
.Yellowline {
  width: 150px;
  margin-top: -13px;
}
.green-bourd-grief {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-grif {
  width: 790px;
  height: 400px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  /* background-image: url("../Images/Arrow.png"); */
  background: #bae8ac;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  /* gap: 5rem; */
}
.card-inner p {
  font-family: myFont;
  color: #256c55;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  margin-top: -3%;
}
.card-inner h1 {
  font-family: Relo;
  font-weight: 900;
  font-size: 30px;
}
.card:hover {
  transform: scale(1.04) rotate(1deg);
}

.circle img {
  width: 150px;

  border-radius: 50%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}
.circle-b img {
  width: 250px;

  border-radius: 50%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}
.circle img:nth-child(1) {
  top: -45px;
  left: -65px;
}

.circle-b img {
  width: 700px;
  bottom: 55px;
  right: -79px;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}
.weeekly-meetup-box {
  background-color: white;
  border: 1px solid #ffdd7e;
  width: 300px;
 min-height: 650px;
  border-radius: 20px;
  gap: 1rem;
  padding: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.weeekly-meetup-box:nth-child(odd) {
  background-color: #ffdd7e;
}
.weeekly-meetup-box img,
p {
  padding: 20px;
  font-family: "Questrial", sans-serif;
}
.weeekly-meetup-box h2 {
  padding: 10;
  font-family: Relo;
  font-weight: 900;
  font-size: 20px;
}
.weekly-meetup-box-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  align-items: center;
  gap: 3rem;
  /* border: 1px solid saddlebrown; */
}
.grief-suppot-form {
    background-color: #bae8ac;
    padding: 10px;
    margin: 5% 20%;
    width: 60%;
    height: auto;
    margin-bottom: -6%;
    border-radius: 20px;
}
.grief-suppot-form h1 {
  padding: 2px;
  text-align: center;
  font-family: Relo;
  font-weight: 900;
  font-size: 30px;
}
.grief-suppot-form h2 {
  padding: 2px;
  text-align: center;
  font-family: myFont;
  color: #256c55;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  margin-top: -3%;
}
.grif-form-inputs input {
  width: 90%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  margin: 10px;
}
.grif-form-inputs select {
  width: 10%;
  border: none;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  font-family: "Questrial", sans-serif;
}
.grif-form-inputs option {
  border: none;
  font-size: 16px;
}
.grif-form-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem 2rem;
}
.inner-div-form {
  width: 50%;
}
.container-form {
  position: relative;
  max-width: 900px;
  width: 100%;
  height: 500px;
  border-radius: 6px;
  padding: 3px;
  margin: 0 15px;
  /* background-color: #fff; */
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
}
.container-form header {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
.container-form header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 3px;
  width: 27px;
  border-radius: 8px;
  background-color: #4070f4;
}
.container-form form {
  position: relative;
  margin-top: 16px;
  min-height: 290px;
  /* background-color: #fff; */
  /* overflow: hidden; */
}
.containerform form .form {
  position: absolute;
  /* background-color: #fff; */
  transition: 0.3 ease;
}
.container form-form .form.second {
  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
}
form.secActive .form.second {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(0);
}
form.secActive .form.first {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
}
.container form .title {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  margin: 6px 0;
  color: #333;
}
.form-group-grief {
display: grid;
grid-template-columns: repeat(2, 1fr);
}
form .fields .input-field {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 4px 0;
}
.input-field label {
  font-size: 15px;
  font-weight: 500;
  color: #2e2e2e;
}
.input-field input {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 15px;
  /* height: 42px; */
  flex: 1 0 40px;
  margin: 8px 0;
}

.input-field input:is(:focus, :valid) {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}
.input-field input[type="date"] {
  color: #707070;
}
.input-field input[type="date"]:valid {
  color: #333;
}
.container form button,
.backBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  margin: 25px o;
  background-color: #4070f4;
  transition: all 0.3s linear;
  cursor: pointer;
}
.container form button,
.container form .backBtn {
  font-size: 14px;
  font-weight: 400;
}
form button:hover {
  background-color: #265df2;
}
form button i,
form .backBtn {
  margin: 0 6px;
}
form .backBtn i {
  transform: rotate(180deg);
}
form .buttons {
  display: flex;
  align-items: center;
}
form .buttons button,
.backBtn {
  margin-right: 14px;
}
.card-grif img {
  width: 20%;
  z-index: -1;
}

.card-grif {
  height: 40vh;
}

@media (max-width: 750px) {
  .container form {
    overflow-y: scroll;
  }
  .container form::-webkit-scrollbar {
    display: none;
  }
  form .fields .input-field {
    width:100%;
  }
}

@media (max-width: 550px) {
  form .fields .input-field {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .weekly-meetup-box-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    align-items: center;
    gap: 3rem;
    /* border: 1px solid saddlebrown; */
    margin-bottom: 45px;
}
  .grief-main-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
  .radio-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin-left: 5%;
  }
  .card-inner {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin: auto;
    justify-content: center;
    width: inherit;
    height: inherit;
    /* background-image: url(../Images/Arrow.png); */
    background: #bae8ac;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    width: 60%;
    height: 249px;
  }
  .grief-main-heading h1 {
    font-family: Relo;
    font-weight: 900;
    font-size: 40px;
  }
  .grief-main-heading span {
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    text-decoration: underline;
  }
  .grief-main-heading h2 {
    font-family: "Questrial", sans-serif;
    font-size: 16px;
    margin: 28px;
  }
  .card-grif {
    width: 410px;
    height: 170px;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;
  }
  .card-inner img {
    width: 145px;
  }
  .card-inner {
    width: 87%;
    height: auto;
    margin-left: 7%;
    /* margin-top: -14%; */
  }
  .card-inner h1 {
    font-family: Relo;
    font-weight: 900;
    font-size: 20px;
    margin: 20px;
  }
  .card-inner p {
    font-family: myFont;
    color: #256c55;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    margin-top: -3%;
  }
  .circle-b img {
    display: none;
    bottom: 195px;
    right: -15px;
    animation-name: move-down1;
  }
  .circle img {
    display: none;
    bottom: 195px;
    right: -15px;
    animation-name: move-down1;
  }
  .grief-suppot-form {
    background-color: #bae8ac;
    /* padding: 10px; */
    margin: auto;
    width: 91%;
    height: 683px;
  }
  .grief-suppot-form h1 {
    font-size: 20px;
  }
  .grief-suppot-form h2 {
    font-size: 25px;
  }
  .gs-header-inner-text img:nth-child(3) {
    margin-left: 55%;
    margin-top: -17%;
    width: 30%;
  }
  .grif-form-inputs input {
    width: 90%;
    padding: 15px;
    border: none;
    border-radius: 10px;
    margin: 10px;
}
}
@media (min-width: 600px) and (max-width: 1024px) {
  .grief-suppot-form {
    background-color: #bae8ac;
    padding: 10px;
    margin: 4% 2%;
    width: 95%;
    height: 720px;
    margin-bottom: -6%;
    border-radius: 20px;
  }
  .grief-main-heading h2 {
    font-family: "Questrial", sans-serif;
    font-size: 20px;
    margin: 20px;
  }
  
 .card-grif img {
        width: 21%;
        margin-left: -2%;
        margin-top: -32px;
        margin-bottom: 40px;
    }
  .circle img:nth-child(1) {
    top: -38px;
    left: -16px;
    display: none;
  }
      .card-grif {
        width: 575px;
        min-height: 283px;
        transition: all 0.2s;
        position: relative;
        cursor: pointer;
        margin: 0px auto;
    }
  .card-inner h1 {
    font-family: Relo;
    font-weight: 900;
    font-size: 22px;
    margin: auto 5%;
  }
  .card-inner p {
    font-family: myFont;
    color: #256c55;
    font-weight: 400;
    font-style: normal;
    font-size: 45px;
    margin-top: -3%;
  }
 .card-inner {
        display: flex;
        align-items: center;
        /* margin: auto; */
        justify-content: center;
        width: inherit;
        height: inherit;
        /* background-image: url(../Images/Arrow.png); */
        background: #bae8ac;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-radius: 8px;
        width: 90%;
        height: 190px;
        margin-top: -20px;
        margin-left: 30px;
    }
  .circle-b img {
    bottom: -122px;
    right: -44px;
    animation-name: move-down1;
    width: 30%;
  }
  .grief-suppot-form {
    background-color: #bae8ac;
    padding: 10px;
    margin:auto;
    width: 80%;
    min-height: 741px;
    /* margin-bottom: -6%; */
    border-radius: 20px;
  }
}
/* navfor */
