@font-face{
  font-family: nav-link;
  src: url(../Font//LTSaeada-Medium.ttf);
}
.logout{
  width: 30%;
}
.signup-btn-dis{
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  border:2px solid black;
  background-color: #ffdd7e;
  border-radius: 50px;
  width: 80%;
  height: 50px;
  margin: 20px;
  /* margin-left: -10%; */
}
.Navbar-main {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #bae8ac;
  min-height: 150px;
  /* border: 1px solid black; */
}
.Login-btns img{
  width: 50px;
  margin-top: 10px;
  border-radius: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
}
nav {
  margin: 2% auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* border: 1px solid black; */
  width: 100%;
}
.Cloude {
  margin-top: -3%;
  width: 100%;
  /* background-color: red;/ */
}
.Login-btns-new p{
  width: 100%;
}
.Login-btns-new{
  width: 40%;
}
/* .Login-btns {
  background-color: #ffdd7e;
  border-radius: 50px;
  width: 100%;
  height: 50px;
  margin: 20px;
  margin-left: -10%;
} */
nav img {
    width: 28%;
    /* margin-left: 4%; */
}
  .logo-sets img:nth-child(2) {
    
  }
.Login-btns button {
  font-size: 16px;
  background: none;
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
  border: none;
  font: 16px;
  font-weight: 600;
  border-radius: 50px;
  margin-left: -31%;
}
.phone-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  font-size: 20px;
  margin:auto;
}
.Login-btns button:hover {
  transition: 0.1s;
  background-color: white;
  padding: 10px 25px;
}
.navarrow {
  font-size: 30px;
  /* border-radius: 50%; */
}
    .nav-links li {
        border: 1px solid black;
        list-style: none;
        font-size: 40px;
        color: black;
        text-decoration: none;
        text-align: center;
        padding: 15px;
        width: 100%;
        font-family: nav-link;
    }
.navarrow:hover {
  background-color: white;
  padding: 2px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 30px;
}
.nav-icon {
  background: #ffdd7e;
  padding: 5px;
  border-radius: 50%;
}
.nav-icon-close{
  font-size: 40px;
  color:black;

  margin-left: 100px;
  border: 2px solid black;
  border-radius: 50%;
}
.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-links li {
  list-style: none;
}
.nav-icon {
  display: none;
}
.logo-sets{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Navbao{
  width: 100%;
  display: flex;
  align-items: center;
}
.Logout-btns{
    background-color: #ffdd7e;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    border-radius: 50px;
  }
  .Logout-btns button{
    padding: 10px 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
      font-family: "Questrial", sans-serif;
  }
  .Logout-btns button:hover{
    background-color: white;
    border-radius: 50px;
  }
   .Logout-btns img{
    width: 50px;
    border-radius: 50%;
   }
@media screen and (min-width: 10px) and ( max-width: 600px) {
  .signup-btn-dis {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    border: 2px solid black;
    background-color: #ffdd7e;
    border-radius: 50px;
    width: 220%;
    height: 50px;
    margin: 2px;
    margin-left: 102%;
}
  nav {
    margin: 2% auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* border: 1px solid black; */
    width: 100%;
    gap: 0rem;
}
  .navarrow {
    width: 20px;
    margin-left: 5px;
  }
  .navarrow:hover {
    background-color: white;
    /* padding: 10px; */
    border-radius: 50%;
  }

.Cloude {
        margin-top: -2%;
        margin-left: 0%;
        position: absolute;
        width: 100%;
        height: auto;
        /* background-color: red; */
    }
  .Login-btns button:hover {
    transition: 0.1s;
    /* background-color: white; */
    padding: 10px 25px;
  }
  .nav-icon {
    display: block;
    font-size: 40px;
    margin: 40px;
  }
  .nav-links {
   display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        height: 50%;
        padding: 20px;
        left: -100%;
        width: 100%;
        background-color: #ffdd7e;
        min-height: 100vh;
        z-index: 1;
        transition: 1s;
  }
  .nav-links.active {
    left: -0;
  }
  .logout{
    width: 90%;
  }
  .Logout-btns{
    background-color: #ffdd7e;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    border-radius: 50px;
  }
  .Logout-btns button{
    padding: 10px 10px;
    background-color: transparent;
    border: none;
  }
  .Logout-btns button:hover{
    background-color: white;
    border-radius: 50px;
  }
   .Logout-btns img{
    width: 50px;
    border-radius: 50%;
   }
      .Login-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        width: 47%;
        /* background-color: #ffdd7e; */
        height: 50px;    
      }
       nav img {
        width: 144px;
        margin-left: 11%;
        margin-top: 5%;
    }
    .Login-btns-new p:nth-child(1){
      /* color: red; */
      width: 60%;
      font-size: small;
    }
    .nav-links li {
        border: 1px solid black;
        list-style: none;
        font-size: 14px;
        color: black;
        text-decoration: none;
        text-align: center;
        padding: 15px;
        width: 100%;
        font-family: nav-link;
    }
    .logo-sets img:nth-child(1){
      display: none;
    }
    .logo-sets img:nth-child(3){
      display: none;

    }
    .logo-sets img:nth-child(2) {
    /* background-color: red; */
    width: 80%;
    margin-left: -30px;
    margin-top: 0px;
}
.header-main img {
    width: 84%;
    margin-top: 0%;
}
 .Login-btns-new {
    width: 100%;
    margin-top: -6%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
}
.nav-icons:nth-child(1) {
  transform: rotate(90deg);
}
@media screen and (min-width: 600px) {
  .nav-icon {
    display: block;
    margin: 40px;
    font-size: 40px;
  }
    .nav-links li {
        border: 1px solid black;
        list-style: none;
        font-size: 40px;
        color: black;
        text-decoration: none;
        text-align: center;
        padding: 15px;
        width: 100%;
  font-family: nav-link;

    }
  .nav-links li:hover {
    text-align: center;
    width: 100%;
    background-color: aliceblue;
  }
  .nav-links {
    display: grid;
    /* gap: 1rem; */
    place-items: center;
    grid-template-columns: repeat(2, 2fr);
    align-items: center;
    position: absolute;
    top: 0px;
    height: 100vh;
    padding: 10px;
    left: -100%;
    width: 100%;
    background-color: #ffdd7e;
    transition: 1.5s;
    z-index:1;
  }
  .nav-links.active {
    left: -0;
  }
 
}
@media (min-width: 600px) and (max-width: 1024px) {
  .logout{
    width: 50%;
  }
  .Logout-btns{
    background-color: #ffdd7e;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    border-radius: 50px;
  }
  .Logout-btns button{
    padding: 10px 10px;
    background-color: transparent;
    border: none;
  }
  .Logout-btns button:hover{
    background-color: white;
    border-radius: 50px;
  }
   .Logout-btns img{
    width: 50px;
    border-radius: 50%;
   }
  .nav-links li {
        border: 1px solid black;
        list-style: none;
        font-size: 20px;
        color: black;
        text-decoration: none;
        text-align: center;
        padding: 15px;
        width: 95%;
        font-family: nav-link;
    }
     .Login-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
       margin-top: 10px;
        border-radius: 50px;
        width: 100%;
        height: 50px;
        margin-left: -9%;
    }
    .signup-btn-dis {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    border: 2px solid black;
    background-color: #ffdd7e;
    border-radius: 50px;
    width: 100%;
    margin-top: 1%;
    height: 50px;
    margin: 5px;
    /* margin-left: -10%; */
}
.Login-btns-new {
    width: 100%;
}
.logo-sets {
    width: 80%;
}
.phone-icon{
  margin-top: -15%;
}
  nav {
    margin: 2% auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* border: 1px solid black; */
    width: 100%;
}
    nav img {
        width: 35%;
        margin-left: 0%;
        margin-top: -3%;
    }
}
