.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 2.8rem;
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.email-para p {
  /* color: red; */
  margin-top: 15px;
}
.input-container input {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: none;
  background-color: none;
  padding: 0.625rem 70px 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: rgb(69 90 100);
  outline: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

/* .input-container input:focus {
  border: 1px solid rgb(236 72 153);
} */

.invite-btn {
  position: absolute;
  width: 65px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 10;
  border-radius: 4px;
  background-color: rgb(236 72 153);
  color: #fff;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  transition: 0.6s ease;
}

.invite-btn:hover {
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 8px;
}

.input-container input:placeholder-shown ~ .invite-btn {
  pointer-events: none;
  background-color: gray;
  opacity: 0.5;
}
.password {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}
.pass-in {
  border: 1px solid black;
  width: 300px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pass-icons-re {
  font-size: 26px;
  /* margin-left: -30px; */
  padding-right: 10px;
}
.input-container label {
  font-size: 16px;
  margin-bottom: 5px;
}
