.input-field{
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2);
}
.input-field input{
    padding: 10px;
}
.form-btns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;

}
.form-btns button{
    padding: 10px 30px;
    border-radius: 20px;
    border: none;
    background-color: #ffdd7e;
    cursor: pointer;
    
}
.input-field select {
    padding: 10px 60px;
    border-radius: 5px;
    width: 100%;
    margin-left: 30px;
}
.form-affilate{
     display: grid;
    place-items: center;
    grid-template-columns: repeat(2,1fr);
 background-color: #ffdd7e;
    border-radius: 10px;
    width: 100%;
    margin: 10px;
    height: 500px;
    border-radius: 20px;
}
.psyco-form{
    background-color: #ffdd7e;
    border-radius: 10px;
}
@media screen and (max-width:600px) {
    .input-field{
    display: grid;
    place-items: center;
    grid-template-columns: repeat(1,1fr);
}
.input-field select {
    padding: 10px 60px;
    border-radius: 5px;
    width: 100%;
    /* margin-left: 138px; */
}
.form-affilate {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(1, 1fr);

    width: 100%;
    margin: 10px;
    height: 500px;
    border-radius: 20px;
}
.psyco-form{
    background-color: #ffdd7e;
}
}
@media screen and (min-width:600px ) and (max-width:1024px) {
    .input-field{
    display: grid;
    place-items: center;
    grid-template-columns: repeat(1,0.9fr);
}
.input-field select {
    padding: 10px 60px;
    border-radius: 5px;
    width: 100%;
    /* margin-left: 138px; */
}
}