.senses-category-cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: auto;
}
.tele-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-calendly {
  margin-left: 20px;
}
.senses-card {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffdd7e;
  height: 358px;
  width: 320px;
  margin: 30px;
  border-radius: 20px;
}
.get-support {
 
  background-color: #ffdd7e;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;  
  cursor: pointer;
}
.sense-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 20px auto;
}
.sense-main-con{
  min-height: 50vh;
  position: relative;
}
.container {
  position: absolute;
  top: 0;
  left: -97%; /* Initially off-screen */
  width: 100%;
  height: 500px;
  background-color: #f0f0f0;
  transition: left 0.3s ease-out; /* Animation for smooth sliding */
  z-index: 1;
  border-radius: 20px 10px 20px 10px;
}
.open{
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 40px;
  /* padding: 0 1em; */

}

.side-indicater-Video{
  width: 50%;
}
.side-indicater-txt{
  width: 50%;
}
.sense-theropy{
 display: grid;
 place-items: left;
   

}
.sense-theropy li{
  list-style: none;
  font-size: 40px;
    font-family: "Questrial", sans-serif;
}
.sense-theropy h1{
   font-size: 40px;
  font-family: Blacky;
  margin-left: 20px;
}
.side-indicater-Video img{
  width: 80%;
}
.inside-box-sens{
  display: flex;
  align-items: center;
  justify-content: center;
 border:1px solid cadetblue;
  min-height: 200px;
  width: 100%;
  height: 100%;
    border-radius: 20px 10px 20px 10px;
}
.container.active {
  left: 0; /* Slide in when active */
  width: 90%;
}
.side-indicater{
 
}
.senses-card img {
  width: 70%;
}
.senses-card h1 {
  text-align: center;
  margin: 20px;
}
.senestop-icon {
  /* color: red; */
  font-size: 30px;
  margin-left: 250px;
  margin-top: -30%;
  /* position: absolute; */
}
.senesbottom-icon {
  /* color: red; */
  font-size: 30px;
  margin-left: 70%;
  margin-top: 0px;
  /* position: absolute; */
}
.close-calendly {
  position: relative;
}
.secnses-main{
  z-index: -9999;
}
/* .container {
    margin: 0%;
    border: 2px dashed #BDBDBD;
    border-radius: 4%;
    padding: 0 1em;
    min-height: 366px;
    padding: 0 1em;
} */
@media (min-width: 600px) and (max-width: 1024px) {
  .sense-theropy h1 {
    font-size: 28px;
    font-family: Blacky;
    margin-left: 20px;
}
.sense-theropy li {
    list-style: none;
    font-size: 30px;
    font-family: "Questrial", sans-serif;
}
  .senses-category-cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
  .get-support {
    background-color: #ffdd7e;
    border: none;
    border-radius: 20px;
    padding: 10px 77px;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
  }
  .container {
    position: absolute;
    top: 0;
    left: -94%;
    width: 100%;
    height: 500px;
    background-color: #f0f0f0;
    transition: left 0.3s ease-out;
    z-index: 1;
    border-radius: 20px 10px 20px 10px;
}
}
@media screen and (max-width: 600px) {
  .get-support {
    background-color: #ffdd7e;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    margin: 12px 148px;
    cursor: pointer;
  }
  .close-calendly {
    margin-left: 10px;
  }
  .sense-theropy li {
    list-style: none;
    font-size: 25px;
    font-family: "Questrial", sans-serif;
}
.sense-theropy h1 {
    font-size: 24px;
    font-family: Blacky;
    margin-left: 20px;
}
  .senestop-icon {
    /* color: red; */
    font-size: 30px;
    margin-left: 250px;
    margin-top: 247px;
    position: absolute;
}
.supoort-box{
  background-color: #ffdd7e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-height: 400px;
  border-radius: 20px;
  margin: auto;
  position: relative;
}
.support-icon{
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px;
}
.container {
    position: absolute;
    top: 0;
    left: -93%;
    width: 100%;
    height: 500px;
    background-color: #f0f0f0;
    transition: left 0.3s ease-out;
    border-radius: 20px 10px 20px 10px;
}
.container {
    position: absolute;
    top: 0;
    left: -94%;
    width: 100%;
    height: 500px;
    background-color: #f0f0f0;
    transition: left 0.3s ease-out;
    z-index: 1;
    border-radius: 20px 10px 20px 10px;
}
}
