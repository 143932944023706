.breadcrumb-item{
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    
}
.beader-main{
     display: flex;
    align-items: center;
    justify-content: start;
    /* margin-left: -65%; */
    position: absolute;

}
.breadcrumps{
    display: flex;
    align-items: center;
    justify-content: start;
    /* width: 10%; */

}
.back-arrow{
    font-size: 50px;
    margin-right: 5px;
    z-index: 9999;
}
.breadcrumb li{
    list-style: none;
    font-size: 20px;
}